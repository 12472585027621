@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");

.menuCard {
  width: 250px;
  height: 70px;
  background-color: var(--yellow);
  border-radius: 15px 15px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--brown);
  font-family: "Comfortaa";
  font-size: 20px;
  margin-top: -80px;
}

.menuCardWrapper {
  background-color: var(--darkyellow);
  height: 80px;
  border-radius: 15px;
}

.button {
  width: 250px;
  padding: 0;
  border: 0;
  background-color: var(--brown);
  border-radius: 15px;
  cursor: pointer;
  margin: 0 0 16px 0;
}

.button:hover {
  transform: scale(1.05);
  transition: ease-in-out;
}

/* Can be changed later for React */
.button.open .menuCard {
  background-color: var(--lightyellow);
}

.button.open .menuCardWrapper {
  background-color: var(--middleyellow);
}
