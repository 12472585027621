.workouts {
  width: 70%;
  flex-grow: 1;
  background-color: white;

  border-radius: var(--borderRadius);

  right: 40px;
  margin-bottom: 30px;
  margin-left: 16px;
}

.gridContainer {
  width: 90%;
  margin: 30px auto;
  column-gap: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
