@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Poppins:wght@300&display=swap");

/* colors / styles here */
:root {
  --borderRadius: 20px;
  --darkblue: #09093b;
  --brightblue: #01a9e6;
  --green: #80dd47;
  --red: #C31818;
  --grey: #b5b5b5;
  --yellow: #FFC700;
  --darkyellow: #ECB905;
  --lightyellow: #FFE175;
  --middleyellow: #FCD85B;
  --brown: #511D00;
  --darkbrown: #321200;
  --darkred: rgb(198, 28, 28);
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}
