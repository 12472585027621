.screenBackground {
    background: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh;
}

.icon {
    position: absolute;
    right: 40px;
    top: 50px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    width: 100px;
    height: 100px;
    border-radius: 50px;
}
