.container {
  width: 100%;
  aspect-ratio: 4/3;
  /* background-color: green; */
  margin: auto;
  margin-top: 0;
}

.imgDiv {
  background-color: #f7f7f7;
  border-radius: var(--borderRadius);
  top: 0;
  width: 100%;
  height: 80%;
  margin-top: 0;
  align-items: center;
  justify-content: center;
  display: flex;
}

.img {
  max-width: 100%;
  max-height: 100%;
}

.imgDiv:hover {
  box-shadow: inset 0px 0px 0px 8px #009DD6;
  cursor: pointer;
}

.workoutName {
  text-align: center;
  font-size: 18px;
}