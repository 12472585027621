.App {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}
