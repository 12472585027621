@import url("https://fonts.googleapis.com/css2?family=Comfortaa&family=Josefin+Sans:wght@400;700&family=Poppins:wght@300&display=swap");
.reportContainer {
  background: url("../../images/background.jpg") no-repeat center center fixed;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: scroll;
  font-family: "Comfortaa";
}

.title {
  padding-top: 70px;
  margin-left: 10%;
  width: 600px;
}

#modal {
  border-radius: var(--borderRadius);
  background-color: white;
  margin: auto;
  width: 80%;
  height: 60vh;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.inline {
  width: 100%;
  display: inline-flex;
  left: 0;
}

#firstInline {
  padding-top: 20px;
}

.icons {
  width: 65px;
  height: 65px;
  margin: 8.5px 0 8.5px 20px;
}

.text {
  padding: 30px 30px 30px 20px;
  font-size: 1.5rem;
}

.rightAlign {
  padding: 30px;
  font-size: 1.5rem;
  right: 15%;
  position: absolute;
}

.reportBtn {
  position: absolute;
  padding: 2vh 2vw;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  border: none;
  font-size: 1.3rem;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.border {
  width: 95%;
  margin: auto;
  height: 2px;
  background-color: var(--grey);
}

#resumeBtn {
  bottom: 10%;
  right: 30%;
  font-family: 'Comfortaa';
  background-color: var(--brown);
}

#backBtn {
  bottom: 10%;
  right: 10%;
  font-family: 'Comfortaa';
  color: var(--brown);
  background-color: var(--yellow);
}

#resumeBtn:hover {
  background-color: var(--darkbrown);
  cursor: pointer;
}

#backBtn:hover {
  cursor: pointer;
  background-color: var(--darkyellow);
}

#problemList {
  font-size: 1.5rem;
  position: absolute;
  top: 400px;
  margin-left: 150px;
}

li {
  padding: 10px 0;
}
