.exercisePage {
  width: 100vw;
  height: 100vh;
  position: absolute;
  position: absolute;
}

.videoContainer {
  width: auto;
  height: 100%;
  object-fit: fill;
}

#alertMsg {
  position: absolute;
  top: 40px;
  width: 1000px;
  height: 110px;
  background-color: var(--yellow);
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  color: white;
  font-size: 2rem;
  font-family: 'Bowlby One';
  text-shadow: 0px 4px 5px var(--brown);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--brown);
  left: 0;
  right: 0;
  text-align: center;
  line-height: 3.5;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

#endBtn {
  position: absolute;
  right: 2%;
  bottom: 5%;
  padding: 20px 40px;
  background-color: var(--red);
  font-size: 1.5rem;
  font-family: 'Comfortaa';
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  color: white;
  border: none;
}

#endBtn:hover {
  background-color: var(--darkred);
}

#top,
#bottom,
#left,
#right {
  background: var(--grey);
  position: fixed;
}
#left,
#right {
  top: 0;
  bottom: 0;
  width: 30px;
}
#left {
  left: 0;
}
#right {
  right: 0;
}

#top,
#bottom {
  left: 0;
  right: 0;
  height: 30px;
}
#top {
  top: 0;
}
#bottom {
  bottom: 0;
}

.icon {
  position: absolute;
  right: 40px;
  top: 50px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  width: 100px;
  height: 100px;
  border-radius: 50px;
}

.screenBackground {
  background: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
}

.icon {
  position: absolute;
  right: 40px;
  top: 50px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  width: 100px;
  height: 100px;
  border-radius: 50px;
}
