.textBox {
    position: absolute;
    top: 10px;
    width: 1000px;
    height: 110px;
    background-color: var(--yellow);
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    color: white;
    font-size: 2rem;
    font-family: 'Bowlby One';
    text-shadow: 0px 4px 5px var(--brown);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--brown);
    left: 0;
    right: 0;
    text-align: center;
    line-height: 3.5;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}